<template lang="pug">
    .content-bdi
        .content-bdi__header
            HeadIssue
        .content-bdi__body
            .grid.grid-cols-2.gap-8.hidden
                .block
                    .flex.items-center.justify-between
                        h2.subheading(style="padding-bottom:0px") Aktivitas
                        b-button(text="Tambah Aktivitas" text_icon_left="file-plus" kind="primary" @click.native="openActivity = !openActivity")

                    .container-timeline
                        .box-timeline.scrollbox
                            .box-timeline__inner.scrollbox-container
                                .timeline(v-for="item in timeline")
                                    .timeline__time: span {{ item.time }}
                                    .timeline__content: p {{ item.content }}
                .block
                    h2.subheading Jejaring Sosial
                    .card-graph.mt-5
                        .box-network
                            network.box-network__canvas(
                                ref="network"
                                :nodes="nodes"
                                :edges="edges"
                                :options="options"
                            )

            .pb-10.block
                .flex.items-center.justify-between.mb-4
                    //- h2.subheading(style="padding-bottom:0px") Berita &amp; Laporan Terkait
                    h2.subheading(style="padding-bottom:0px") Berita Terkait
                    //- .flex.items-center.justify-between
                        //- .block.w-30.mr-3
                            .field.field--icon-right.w-full
                                select.field-style(v-model="source_related_report" @change="getRelatedReport(true)")
                                    option(value="berita,laporan") Semua
                                    option(value="berita") Berita
                                    option(value="laporan") Laporan
                                span.icon-field
                                    b-icon(name="chevron-down")
                        .box-calendar
                            b-icon(name="calendar")
                            date-range-picker(
                                v-if="dateRange.startDate && dateRange.endDate"
                                ref="picker"
                                v-model="dateRange"
                                @update="pickerUpdate($event)"
                                :locale-data="{ firstDay: 1, format: 'dd mmmm yyyy', applyLabel: 'Terapkan', cancelLabel: 'Batalkan', }"
                                :date-format="dateFormatPicker"
                                opens="left"
                                drops="up"
                                :ranges="default_ranges"
                                :autoApply="false"
                                :showDropdowns="true"
                            )
                                template(slot="input" slot-scope="picker" style="min-width: 350px;") {{ picker.startDate | date }} - {{ picker.endDate | date }}
                .box-report
                    ul
                        li(v-for="item in list_report")
                            //- button(v-if="item.source.toLowerCase() === 'laporan'" @click="openModalReportAction(item.title)")
                                .w-40
                                    .box-label-source
                                        b-icon(name="file-text" size="small").mr-2
                                        span.text-xs.font-bold {{ item.source ? item.source : '' }}
                                .block
                                    p(v-html="item.title")
                                    span.text-xs {{ datetimeFormat(checkString(item.publishedAt, item.publish_date), 'YYYY-MM-DD HH:mm:ss',('DD MMM YYYY')) }}

                            a(:href="checkString(item.url, item.source_url, '#')" target='_blank').flex.w-full
                                .w-40
                                    .box-label-source
                                        b-icon(name="external-link" size="small").mr-2
                                        span.text-xs.font-bold Berita
                                .block
                                    p(v-html="item.title")
                                    span.text-xs {{ item.source && item.source.length > 0 ? item.source +' - ' : '' }}{{ datetimeFormat(checkString(item.publishedAt, item.publish_date), 'YYYY-MM-DD HH:mm',('DD MMM YYYY')) }}

                    template(v-if="list_report.length > 0 || status_list_news.status === 'loading'")
                        .relative.mt-5.mb-5(v-if="status_list_news.status === 'loading'")
                            b-loading(kind="circular")
                        //- template(v-else-if="list_report.length > 0 && (related_report && related_report._meta && related_report._meta.pagination && related_report._meta.pagination.has_next==true)")
                        template(v-else-if="list_report.length > 0 && (list_news.pagination && list_news.pagination.hasNext && list_news.pagination.hasNext === true)")
                            .flex.items-center.justify-center.w-full.py-4
                                b-button(text="Tampilkan berita lainnya..." size="small" kind="tertiary" @click.native="loadMoreReport()")

                    .flex.items-center.justify-center(v-else style="min-height:414px")
                        span Berita tidak ditemukan

            //- .pb-10
                template(v-if="status_list_trend.status != 'loading' && list_trend.doc && list_trend.doc.length == 0")
                    .card-bg-theme.p-4.rounded
                        .w-full.h-80.flex.items-center.justify-center
                            .block
                                b-icon.mb-4(name="info" size="2x")
                                p Belum ada Trend
                template(v-else)
                    h2.subheading Trend Terkait

                    .bdi-trend
                        //- sidebar list of trend founded
                        .bdi-trend__sidebar.scrollbox
                            .bdi-trend-sidebar.p-2.scrollbox-container
                                template(v-if="list_data_topic.length > 0")
                                    .w-full(v-if="list_data_topic.length > 0")
                                        button.list-trend(
                                            v-for="(item, key) in list_data_topic"
                                            @click="selectTopic(item, key)"
                                            :class="key == active_topic? 'active':''"
                                            v-tooltip="item.label"
                                        ) {{ item.label }}

                        //- content
                        .bdi-trend__content
                            //- all twwets
                            .bdi-trend-content
                                .trend-based-issue
                                    .trend-based-issue__header
                                        strong Feeds
                                    .trend-based-issue__body.scrollbox
                                        .issue-tweets.scrollbox-container
                                            //- PostFeed(
                                                v-for="item in FeedSocialMedia"
                                                :photo="item.photo"
                                                :username="item.username"
                                                :date="item.date"
                                                :content="item.content"
                                                :url="item.url"
                                                :socmed="item.socmed"
                                            //- )
                                            PostFeed(
                                                v-for="item in list_data_feed"
                                                :key="item.url"
                                                :photo="item.extra_author_attributes.photo"
                                                :username="item.extra_author_attributes.username"
                                                :date="datetimeFormat(item.created_at,'DD-MM-YYYY HH:mm:ss', 'DD/MM/YY')"
                                                :content="item.content"
                                                :url="item.url"
                                                :socmed="item.source_type.toLowerCase()"
                                            )
                                            //- PostTweet.w-full(
                                                v-for="item in list_data_feed"
                                                :photo="item.extra_author_attributes.photo"
                                                :username="'@'+item.extra_author_attributes.username"
                                                :date="datetimeFormat(item.created_at,'DD-MM-YYYY HH:mm:ss', 'DD/MM/YY')"
                                                :content="item.content"
                                                :key="item.username"
                                            //- )

                                        template(v-if="list_data_feed.length > 0 || status_list_feed.status == 'loading'")
                                            .relative.mt-10(v-if="status_list_feed.status === 'loading'")
                                                b-loading(text="Memuat tweets lainnya..." kind="circular")
                                            template(v-else)
                                                .flex.items-center.justify-center.w-full.py-4.pt-0.pb-4
                                                    b-button(
                                                        kind="tertiary"
                                                        text="Muat tweets lainnya"
                                                        size="small"
                                                        v-if="list_data_feed.length < parseInt(list_feed.total_item)"
                                                        @click.native="loadMoreTweet()"
                                                    )
                                        .absolute.w-full.h-full.inset-0.flex.items-center.justify-center(v-else)
                                            .block
                                                b-icon.mb-4(name="info" size="2x")
                                                p Feeds tidak ditemukan.

                                    .trend-based-issue__footer
                                        | Menampilkan 1 - {{ list_data_feed.length }} dari {{ list_feed.total_item }} Feeds

                        //- count the tweets
                        .bdi-trend__sidebar
                            .bdi-trend-sidebar
                                .trend-based-issue
                                    .trend-based-issue__header
                                        strong Isu Teramai
                                    .trend-based-issue__body.scrollbox
                                        .issue-tweets.scrollbox-container
                                            template(v-if="status_list_trending.status === 'loading'")
                                                b-loading.b-loading-inherit(text="Memuat isu teramai..." kind="circular")
                                            .popular-issue(v-else-if="list_trending && list_trending.items && list_trending.items.length > 0")
                                                //- button.popular-issue__item(v-for="item in list_trending.items" @click="getDetailBoxTrending(item.title)")
                                                button.popular-issue__item(v-for="item in list_trending.items")
                                                    strong.block.mb-05 {{ item.title}}
                                                    span.block.mb-2 {{ numberWithDot(item.count) }}
                                                    .bar( :style="'width:' + item.count / maxCount * 100 + '%'")
                                                    //- .bar( :style="'width:' + ((item.count / maxCount * 95)+5) + '%'")
                                                //- .flex.items-center.justify-center.w-full.py-4.pt-0.pb-4
                                                    b-button(kind="tertiary" text="Muat Cutian Lainnya" size="small")
                                            .absolute.w-full.h-full.inset-0.flex.items-center.justify-center(v-else)
                                                .block
                                                    b-icon.mb-4(name="info" size="2x")
                                                    p Isu Teramai tidak ditemukan.

            //-  DECKY INI FILE LAMA
            //- .pb-10.hidden
                h2.subheading Trend Terkait
                .box-activity-topic
                    .box-activity-topic__sidebar
                        .links-topic.scrollbox
                            .sidebar-activity-topic.scrollbox-container

                                //- routerLink( v-for="item in topicActivity" :to="item.url") {{ item.name }}
                                template(v-if="list_data_topic.length > 0")
                                    ul.box-topic(v-if="list_data_topic.length > 0")
                                        li(
                                            v-for="(item, key) in list_data_topic"
                                            @click="selectTopic(item, key)"
                                            :class="key == active_topic? 'active':''"
                                            v-tooltip="item.label"
                                        ) {{ item.label }}
                    .box-activity-topic__content
                        .topic-issue
                            .topic-issue__tweet
                                .topic-issue-content
                                    .topic-issue-content__header.flex.items-center.justify-between
                                        strong Tweets
                                        span.text-xs Menampilkan 1 - {{ numberWithDot(list_data_feed.length) }} dari {{ numberWithDot(list_feed.total_item) }} Tweets
                                    .topic-issue-content__body.scrollbox
                                        .issue-content.scrollbox-container
                                            PostTweet.w-full(
                                                v-for="item in list_data_feed"
                                                :photo="item.extra_author_attributes.photo"
                                                :username="'@'+item.extra_author_attributes.username"
                                                :date="datetimeFormat(item.created_at,'DD-MM-YYYY HH:mm:ss', 'DD/MM/YY')"
                                                :content="item.content"
                                                :key="item.username"
                                            )
                                            //- .flex.items-center.justify-center.w-full.py-4.pt-0.pb-4
                                                b-button(kind="tertiary" text="Muat Cutian Lainnya" size="small")

                                        template(v-if="list_data_feed.length > 0 || status_list_feed.status == 'loading'")
                                            .relative.mt-10(v-if="status_list_feed.status === 'loading'")
                                                b-loading(text="Memuat data..." kind="circular")
                                            template(v-else)
                                                .flex.items-center.justify-center.w-full.py-4.pt-0.pb-4
                                                    b-button(
                                                        kind="tertiary"
                                                        text="Muat Tweets Lainnya"
                                                        size="small"
                                                        v-if="list_data_feed.length < parseInt(list_feed.total_item)"
                                                        @click.native="loadMoreTweet()"
                                                    )
                                                //- .count-twitter Menampilkan 1 - {{ numberWithDot(list_data_feed.length) }} dari {{ numberWithDot(list_feed.total_item) }} Tweets
                                        .absolute.w-full.h-full.inset-0.flex.items-center.justify-center(v-else)
                                            .block
                                                b-icon.mb-4(name="info" size="2x")
                                                p Tweets tidak ditemukan.
                            .topic-issue__trending.relative
                                .topic-issue-content
                                    .topic-issue-content__header
                                        strong Isu Teramai
                                    .topic-issue-content__body.scrollbox
                                        .issue-content.scrollbox-container
                                            template(v-if="status_list_trending.status === 'loading'")
                                                b-loading.b-loading-inherit(text="Memuat data..." kind="circular")
                                            .popular-issue(v-else-if="list_trending && list_trending.items && list_trending.items.length > 0")
                                                button.popular-issue__item(v-for="item in list_trending.items" @click="getDetailBoxTrending(item.title)")
                                                    strong.block.mb-05 {{ item.title}}
                                                    span.block.mb-2 {{ numberWithDot(item.count) }}
                                                    .bar( :style="'width:' + item.count / maxCount * 100 + '%'")
                                                    //- .bar( :style="'width:' + ((item.count / maxCount * 95)+5) + '%'")
                                                .flex.items-center.justify-center.w-full.py-4.pt-0.pb-4
                                                    b-button(kind="tertiary" text="Muat Cutian Lainnya" size="small")
                                            .absolute.w-full.h-full.inset-0.flex.items-center.justify-center(v-else)
                                                .block
                                                    b-icon.mb-4(name="info" size="2x")
                                                    p Isu Teramai tidak ditemukan.
                            //- .topic-issue__detail
                                p helo

        //- open popup
        .modal(v-bind:class="{'modal--show' : openActivity}")
            .modal-overlay
            .modal-container
                .modal-container__header
                    h2 Tambah Aktivitas
                    b-button(icon_only="x" @click.native="openActivity = !openActivity")
                .modal-container__body
                    .p-4.pb-0
                        .field.mb-4
                            input.field-style(type="date" value="")
                        .field.mb-4
                            textarea.field-style(placeholder="Tulis Keterangan disini" style="min-height:200px")
                .modal-container__footer
                    b-button(text="Simpan" kind="primary" @click.native="openActivity = !openActivity")

        //- POPUP DETAIL LAPORAN
        //- .modal.modal--filled(v-bind:class="{'modal--show' : openModalReport}" style="z-index: 9999" ref="bigSearchDetailReport")
            .modal-container
                .modal-container__header
                    .flex-grow
                        h2 Laporan
                    b-button(icon_only="x" @click.native="openModalReport = !openModalReport; titleModalReport = '';" type="button")
                .modal-container__body
                    .relative.mt-10(v-if="status_detail_report === 'loading'")
                        b-loading(kind="circular")

                    template(v-else)
                        h2.subheading {{ titleModalReport }}
                        template(v-if="detail_report && Object.keys(detail_report).length > 0")
                            //- p Lorem ipsum dolor sit amet, cons
                            //- p(v-for="(item,index) in detail_report" :key="index") {{ item.description_raw ? item.description_raw : item.description }}
                            //- p(v-for="(item,index) in detail_report" :key="index") {{ item.description }}
                            div(v-for="(item,index) in detail_report" :key="index")
                                hr
                                p.mt-5.mb-5(v-html="JSON.parse(item.description_raw)")

                        .block.text-center.mb-5(v-else)
                            hr.mb-10
                            span Detail laporan tidak ditemukan.

</template>

<script>

import {mapState} from 'vuex';
import HeadIssue from '@/pages/issue/HeadIssue';
import snapShotAccount from '@/components/SnapShot';
import PostTweet from '@/components/PostTweet';
import PostFeed from '@/components/PostFeed';
import DateRangePicker from 'vue2-daterange-picker';
import moment from 'moment';
import 'vue2-daterange-picker/dist/vue2-daterange-picker.css';

export default {
    name: 'ActivitiesOfIssue',
    components: {
        HeadIssue,
        snapShotAccount,
        PostTweet,
        PostFeed,
        DateRangePicker,
    },
    data() {
        return {
            openActivity: false,
            loadingTweeter: false,
            maxCount: 700,
            activeSectionIssue: false,
            boxPopularIssue: false,
            activeSectionTweets: true,
            boxTweets: true,

            // openModalReport: false,
            // titleModalReport: '',

            source_related_report: 'berita,laporan',
            list_report: [],
            page_report: 1,
            limit_report: 10,
            dateRange: {
                // startDate: moment().subtract(6, 'days').toDate(),
                startDate: this.getDefaultFromDate(),
                endDate: moment().toDate(),
            },
            default_ranges: {
                'Today': [moment().toDate(), moment().toDate()],
                'Yesterday': [moment().subtract(1, 'days').toDate(), moment().subtract(1, 'days').toDate()],
                'Last 7 days': [moment().subtract(6, 'days').toDate(), moment().toDate()],
                'Last week': [moment().subtract(1, 'isoWeek').startOf('isoWeek').toDate(), moment().subtract(1, 'isoWeek').endOf('isoWeek').toDate()],
            },

            // detail account
            detailBoxAccount: true,
            accountLoaded: true,

            // detail Issue
            detailBoxIssue: false,

            FeedSocialMedia: [
                {
                    photo: 'https://randomuser.me/api/portraits/women/9.jpg',
                    username: 'LaylaTerry',
                    date: '25/11/21',
                    content: 'Buruh Menanti Iktikad Baik Kepala Daerah soal Revisi Upah Minimum',
                    url: 'https:/google.com',
                    socmed: 'facebook',
                },
                {
                    photo: 'https://randomuser.me/api/portraits/men/9.jpg',
                    username: 'LaylaTerry',
                    date: '25/11/21',
                    content: '... melakukan revisi dgn kembali mengacu kepada UU Nomor 13/ 2003 dan PP nomor 78/ 2015. KSPI akan memaksimalkan aksi massa sampai dengan gubernur @aniesbaswedan memenuhi tuntutan mereka terkait UMP @DKIJakarta 2022 tanpa Omnibus Law yg sudah dinyatakan Inkonsitusional oleh MK.',
                    url: 'https:/google.com',
                    socmed: 'twitter',
                },
                {
                    photo: 'https://randomuser.me/api/portraits/men/19.jpg',
                    username: 'LaylaTerry',
                    date: '25/11/21',
                    content: 'Buruh Menanti Iktikad Baik Kepala Daerah soal Revisi Upah Minimum',
                    url: 'https:/google.com',
                    socmed: 'instagram',
                },
                {
                    photo: 'https://randomuser.me/api/portraits/men/91.jpg',
                    username: 'LaylaTerry',
                    date: '25/11/21',
                    content: 'Buruh Menanti Iktikad Baik Kepala Daerah soal Revisi Upah Minimum',
                    url: 'https:/google.com',
                    socmed: 'youtube',
                },
                {
                    photo: 'https://randomuser.me/api/portraits/women/9.jpg',
                    username: 'LaylaTerry',
                    date: '25/11/21',
                    content: 'Buruh Menanti Iktikad Baik Kepala Daerah soal Revisi Upah Minimum',
                    url: 'https:/google.com',
                    socmed: 'facebook',
                },
                {
                    photo: 'https://randomuser.me/api/portraits/men/9.jpg',
                    username: 'LaylaTerry',
                    date: '25/11/21',
                    content: '... melakukan revisi dgn kembali mengacu kepada UU Nomor 13/ 2003 dan PP nomor 78/ 2015. KSPI akan memaksimalkan aksi massa sampai dengan gubernur @aniesbaswedan memenuhi tuntutan mereka terkait UMP @DKIJakarta 2022 tanpa Omnibus Law yg sudah dinyatakan Inkonsitusional oleh MK.',
                    url: 'https:/google.com',
                    socmed: 'twitter',
                },
                {
                    photo: 'https://randomuser.me/api/portraits/men/19.jpg',
                    username: 'LaylaTerry',
                    date: '25/11/21',
                    content: 'Buruh Menanti Iktikad Baik Kepala Daerah soal Revisi Upah Minimum',
                    url: 'https:/google.com',
                    socmed: 'instagram',
                },
                {
                    photo: 'https://randomuser.me/api/portraits/men/91.jpg',
                    username: 'LaylaTerry',
                    date: '25/11/21',
                    content: 'Buruh Menanti Iktikad Baik Kepala Daerah soal Revisi Upah Minimum',
                    url: 'https:/google.com',
                    socmed: 'youtube',
                },
                {
                    photo: 'https://randomuser.me/api/portraits/women/9.jpg',
                    username: 'LaylaTerry',
                    date: '25/11/21',
                    content: 'Buruh Menanti Iktikad Baik Kepala Daerah soal Revisi Upah Minimum',
                    url: 'https:/google.com',
                    socmed: 'facebook',
                },
                {
                    photo: 'https://randomuser.me/api/portraits/men/9.jpg',
                    username: 'LaylaTerry',
                    date: '25/11/21',
                    content: '... melakukan revisi dgn kembali mengacu kepada UU Nomor 13/ 2003 dan PP nomor 78/ 2015. KSPI akan memaksimalkan aksi massa sampai dengan gubernur @aniesbaswedan memenuhi tuntutan mereka terkait UMP @DKIJakarta 2022 tanpa Omnibus Law yg sudah dinyatakan Inkonsitusional oleh MK.',
                    url: 'https:/google.com',
                    socmed: 'twitter',
                },
                {
                    photo: 'https://randomuser.me/api/portraits/men/19.jpg',
                    username: 'LaylaTerry',
                    date: '25/11/21',
                    content: 'Buruh Menanti Iktikad Baik Kepala Daerah soal Revisi Upah Minimum',
                    url: 'https:/google.com',
                    socmed: 'instagram',
                },
                {
                    photo: 'https://randomuser.me/api/portraits/men/91.jpg',
                    username: 'LaylaTerry',
                    date: '25/11/21',
                    content: 'Buruh Menanti Iktikad Baik Kepala Daerah soal Revisi Upah Minimum',
                    url: 'https:/google.com',
                    socmed: 'youtube',
                },
            ],

            timeline: [
                {
                    time: '16 Juni 20201',
                    content: 'Test Update 2',
                },
                {
                    time: '29-30 Maret 2021',
                    content: 'Bentrok fisik antara Angkatan Muda Partai Golkar (AMPG) kubu Yorrys dan kubu Aburizal Bakrie',
                },
                {
                    time: '29-30 Maret 2021',
                    content: 'Bentrok fisik antara Angkatan Muda Partai Golkar (AMPG) kubu Yorrys dan kubu Aburizal Bakrie',
                },
                {
                    time: '29-30 Maret 2021',
                    content: 'Lorem ipsum dolor sit amet, consectetur adipisicing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Lorem ipsum dolor sit amet, consectetur adipisicing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.',
                },
                {
                    time: '29-30 Maret 2021',
                    content: 'Bentrok fisik antara Angkatan Muda Partai Golkar (AMPG) kubu Yorrys dan kubu Aburizal Bakrie',
                },
            ],
            nodes: [
                {
                    id: 1,
                    label: 'Tokoh 1',
                    shape: 'circle',
                    color: 'rgb(21, 91, 240)',
                    font: {color: 'white', size: 12},
                },
                {
                    id: 2,
                    label: 'Tokoh 2',
                    shape: 'circle',
                    color: 'rgb(21, 91, 240)',
                    font: {color: 'white', size: 12},
                },
                {
                    id: 3,
                    label: 'Tokoh 3',
                    shape: 'circle',
                    color: 'rgb(21, 91, 240)',
                    font: {color: 'white', size: 12},
                },
                {
                    id: 4,
                    label: 'Tokoh 4',
                    shape: 'circle',
                    color: 'rgb(21, 91, 240)',
                    font: {color: 'white', size: 12},
                },
                {
                    id: 5,
                    label: 'Tokoh 5',
                    shape: 'circle',
                    color: 'rgb(21, 91, 240)',
                    font: {color: 'white', size: 12},
                },
                {
                    id: 6,
                    label: 'Tokoh 6',
                    shape: 'circle',
                    color: 'rgb(21, 91, 240)',
                    font: {color: 'white', size: 12},
                },
            ],
            edges: [
                {from: 1, to: 4},
                {from: 2, to: 4},
                {from: 3, to: 4},
                {from: 5, to: 4},
                {from: 5, to: 6},
            ],
            options: {
                nodes: {
                    borderWidth: 0,
                    widthConstraint: {
                        maximum: 150,
                    },
                },
                edges: {
                    color: 'rgb(21, 91, 240)',
                },
            },
            topicActivity: [
                {
                    name: 'Nama Topik ABC',
                    url: '/organisasi/name/aktivitas/isu-1',
                },
                {
                    name: 'Topik Related 1',
                    url: '/organisasi/name/aktivitas/isu-2',
                },
                {
                    name: 'Topik Related 2',
                    url: '/organisasi/name/aktivitas/isu-3',
                },
                {
                    name: 'Topik Related 3',
                    url: '/organisasi/name/aktivitas/isu-2',
                },
                {
                    name: 'Topik Related 4',
                    url: '/organisasi/name/aktivitas/isu-3',
                },
                {
                    name: 'Topik Related 5',
                    url: '/organisasi/name/aktivitas/isu-2',
                },
                {
                    name: 'Topik Related 6',
                    url: '/organisasi/name/aktivitas/isu-3',
                },
                {
                    name: 'Topik Related 7',
                    url: '/organisasi/name/aktivitas/isu-2',
                },
                {
                    name: 'Topik Related 8',
                    url: '/organisasi/name/aktivitas/isu-3',
                },
                {
                    name: 'Topik Related 9',
                    url: '/organisasi/name/aktivitas/isu-2',
                },
                {
                    name: 'Topik Related 10',
                    url: '/organisasi/name/aktivitas/isu-3',
                },
                {
                    name: 'Topik Related 11',
                    url: '/organisasi/name/aktivitas/isu-2',
                },
                {
                    name: 'Topik Related 12',
                    url: '/organisasi/name/aktivitas/isu-3',
                },
                {
                    name: 'Topik Related 13',
                    url: '/organisasi/name/aktivitas/isu-2',
                },
                {
                    name: 'Topik Related 14',
                    url: '/organisasi/name/aktivitas/isu-3',
                },
                {
                    name: 'Topik Related 15',
                    url: '/organisasi/name/aktivitas/isu-2',
                },
                {
                    name: 'Topik Related 16',
                    url: '/organisasi/name/aktivitas/isu-3',
                },
                {
                    name: 'Topik Related 17',
                    url: '/organisasi/name/aktivitas/isu-2',
                },
                {
                    name: 'Topik Related 18',
                    url: '/organisasi/name/aktivitas/isu-3',
                },
                {
                    name: 'Topik Related 19',
                    url: '/organisasi/name/aktivitas/isu-2',
                },
                {
                    name: 'Topik Related 20',
                    url: '/organisasi/name/aktivitas/isu-3',
                },
                {
                    name: 'Topik Related 21',
                    url: '/organisasi/name/aktivitas/isu-2',
                },
                {
                    name: 'Topik Related 22',
                    url: '/organisasi/name/aktivitas/isu-3',
                },
                {
                    name: 'Topik Related 23',
                    url: '/organisasi/name/aktivitas/isu-2',
                },
                {
                    name: 'Topik Related 24',
                    url: '/organisasi/name/aktivitas/isu-3',
                },
            ],
            relatedTweetsTopic: [
                {
                    username: '@pandji',
                    content: 'Rasanya gue ga pernah liat polisi minta maaf seperti ini sebelumnya. Gestur yg baik. Gotta give credit when credit is due.',
                    date: '11/12/21',
                    avatar: 'https://pbs.twimg.com/profile_images/1341056153468354574/kcfQnHeI_400x400.jpg',
                },
                {
                    username: '@Jennings',
                    content: 'Want to create your own customized data generator for your application? Check out our other service RandomAPI!',
                    date: '11/12/21',
                    avatar: 'https://randomuser.me/api/portraits/men/25.jpg',
                },
                {
                    username: '@Bishop',
                    content: 'Want to create your own customized data generator for your application? Check out our other service RandomAPI!',
                    date: '11/12/21',
                    avatar: 'https://randomuser.me/api/portraits/men/45.jpg',
                },
                {
                    username: '@Jackson',
                    content: 'Want to create your own customized data generator for your application? Check out our other service RandomAPI!',
                    date: '11/12/21',
                    avatar: 'https://randomuser.me/api/portraits/men/15.jpg',
                },
                {
                    username: '@Stanley',
                    content: 'Want to create your own customized data generator for your application? Check out our other service RandomAPI!',
                    date: '11/12/21',
                    avatar: 'https://randomuser.me/api/portraits/men/15.jpg',
                },
                {
                    username: '@Jennings',
                    content: 'Want to create your own customized data generator for your application? Check out our other service RandomAPI!',
                    date: '11/12/21',
                    avatar: 'https://randomuser.me/api/portraits/men/25.jpg',
                },
                {
                    username: '@Bishop',
                    content: 'Want to create your own customized data generator for your application? Check out our other service RandomAPI!',
                    date: '11/12/21',
                    avatar: 'https://randomuser.me/api/portraits/men/45.jpg',
                },
                {
                    username: '@Jackson',
                    content: 'Want to create your own customized data generator for your application? Check out our other service RandomAPI!',
                    date: '11/12/21',
                    avatar: 'https://randomuser.me/api/portraits/men/15.jpg',
                },
                {
                    username: '@Stanley',
                    content: 'Want to create your own customized data generator for your application? Check out our other service RandomAPI!',
                    date: '11/12/21',
                    avatar: 'https://randomuser.me/api/portraits/men/15.jpg',
                },
                {
                    username: '@Jennings',
                    content: 'Want to create your own customized data generator for your application? Check out our other service RandomAPI!',
                    date: '11/12/21',
                    avatar: 'https://randomuser.me/api/portraits/men/25.jpg',
                },
                {
                    username: '@Bishop',
                    content: 'Want to create your own customized data generator for your application? Check out our other service RandomAPI!',
                    date: '11/12/21',
                    avatar: 'https://randomuser.me/api/portraits/men/45.jpg',
                },
                {
                    username: '@Jackson',
                    content: 'Want to create your own customized data generator for your application? Check out our other service RandomAPI!',
                    date: '11/12/21',
                    avatar: 'https://randomuser.me/api/portraits/men/15.jpg',
                },
            ],
            popularIssue: [
                {
                    keyword: 'Putri KW',
                    count: 700,
                },
                {
                    keyword: '#KuotaSultanHargaTeman',
                    count: 550,
                },
                {
                    keyword: 'Ginting',
                    count: 400,
                },
                {
                    keyword: 'Cardi B',
                    count: 350,
                },
                {
                    keyword: '#PSSday',
                    count: 200,
                },
                {
                    keyword: 'Messi',
                    count: 150,
                },
                {
                    keyword: 'PSG',
                    count: 120,
                },
                {
                    keyword: 'Real Madrid',
                    count: 110,
                },
                {
                    keyword: '#WhatIf',
                    count: 100,
                },
                {
                    keyword: 'Sheriff',
                    count: 90,
                },
                {
                    keyword: 'Zidane',
                    count: 20,
                },
                {
                    keyword: 'Benzema',
                    count: 20,
                },
            ],
            following: [
                {
                    url: 'tw.com/@ernestprakasa',
                    account: '@ernestprakasa',
                },
                {
                    url: 'tw.com/@Adriandhy',
                    account: '@Adriandhy',
                },
                {
                    url: 'tw.com/@Arie_Kriting',
                    account: '@Arie_Kriting',
                },
                {
                    url: 'tw.com/@adjisdoaibu',
                    account: '@adjisdoaibu',
                },
                {
                    url: 'tw.com/@adjisdoaibu',
                    account: '@adjisdoaibu',
                },
                {
                    url: 'tw.com/@solehsolihin',
                    account: '@solehsolihin',
                },
                {
                    url: 'tw.com/@anggasasongko',
                    account: '@anggasasongko',
                },
                {
                    url: 'tw.com/@gilbhas',
                    account: '@gilbhas',
                },
                {
                    url: 'tw.com/@MuhadklyAcho',
                    account: '@MuhadklyAcho',
                },
                {
                    url: 'tw.com/@aparatmati',
                    account: '@aparatmati',
                },
                {
                    url: 'tw.com/@_BangFu',
                    account: '@_BangFu',
                },
                {
                    url: 'tw.com/@vngnc',
                    account: '@vngnc',
                },
                {
                    url: 'tw.com/@davidbayu',
                    account: '@davidbayu',
                },
                {
                    url: 'tw.com/@jokoanwar',
                    account: '@jokoanwar',
                },
                {
                    url: 'tw.com/@aniesbaswedan',
                    account: '@aniesbaswedan',
                },
                {
                    url: 'tw.com/@jokowi',
                    account: '@jokowi',
                },
                {
                    url: 'tw.com/@prabowo',
                    account: '@prabowo',
                },
                {
                    url: 'tw.com/@RidwanKamil',
                    account: '@RidwanKamil',
                },
                {
                    url: 'tw.com/@radityaDika',
                    account: '@radityaDika',
                },
                {
                    url: 'tw.com/@abandaHerrman',
                    account: '@abandaHerrman',
                },
                {
                    url: 'tw.com/@testingBotPandji',
                    account: '@testingBotPandji',
                },
                {
                    url: 'tw.com/@babecabita',
                    account: '@babecabita',
                },
                {
                    url: 'tw.com/@PeduliLundungi',
                    account: '@PeduliLundungi',
                },
            ],
            list_data_topic: [],
            limit: 9,
            page: 1,
            keyword: '',
            timeout: null,

            active_topic: null,

            list_data_feed: [],
            limit_tweet: 20,
            page_tweet: 1,

        };
    },
    computed: {
        id() {
            return this.$route.params.id;
        },
        ...mapState('trend', [
            'status_list_trend',
            'list_trend',
        ]),
        ...mapState('medsos', [
            'status_list_trending',
            'status_list_feed',
            'list_trending',
            'list_feed',
        ]),
        ...mapState('issue', [
            'status_detail',
            'detail',
        ]),
        ...mapState('news', [
            // 'status_related_report',
            // 'related_report',
            // 'status_detail_report',
            // 'detail_report',
            'status_list_news',
            'list_news',
        ]),
    },
    watch: {
        list_trend(newVal) {
            // console.log(newVal)
            if (newVal && newVal.doc && newVal.doc.length > 0) {
                for (let i = 0; i < newVal.doc.length; i++) {
                    this.list_data_topic.push(newVal.doc[i]);
                }
                if (this.page == 1) {
                    this.active_topic=0;
                    this.slugTopic = newVal.doc[0].slug;
                    // this.getDataTweet(this.slugTopic, true);
                    // this.getDataTrending();
                }
            }
        },
        list_trending(newVal) {
            if (newVal.items && newVal.items.length > 0) {
                this.maxCount = newVal.items[0].count;
            }
        },
        list_feed(newVal) {
            if (newVal.items && newVal.items.length > 0) {
                for (let i = 0; i < newVal.items.length; i++) {
                    this.list_data_feed.push(newVal.items[i]);
                }
            }
        },
        status_detail(newVal, oldVal) {
            if (newVal !== 'loading' && oldVal === 'loading') {
                this.getRelatedReport(true);
            }
        },
        // related_report(newVal) {
        //     if (newVal.data && newVal.data.length > 0) {
        //         for (let i = 0; i < newVal.data.length; i++) {
        //             this.list_report.push(newVal.data[i]);
        //         }
        //     }
        // },
        list_news(newVal) {
            if (newVal.doc && newVal.doc.length > 0) {
                for (let i = 0; i < newVal.doc.length; i++) {
                    this.list_report.push(newVal.doc[i]);
                }
            }
        },
    },
    methods: {
        selectTopic(data, key) {
            // if (key !== this.active_topic) {
            this.active_topic=key;
            this.slugTopic = data.slug;
            // this.getDataTweet(this.slugTopic, true);
            // this.getDataTrending();
            // }
        },
        loadMoreTweet() {
            this.page_tweet += 1;
            this.getDataTweet(this.slugTopic);
        },
        getDataTweet(slug, reset=false) {
            if (reset) {
                this.list_data_feed = [];
                this.page_tweet = 1;
            }
            // get list tweet
            this.$store.dispatch('medsos/getListFeed', [this.limit_tweet, this.page_tweet, '', '', slug] );
        },
        getDataTrending() {
            // get trending
            this.$store.dispatch('medsos/getListTrending', []);
        },
        getData(reset=false) {
            if (reset) {
                this.list_data_topic = [];
                this.page = 1;
            }
            this.$store.dispatch('trend/getListTrend', [this.limit, this.page, '', this.id, 'true']);
        },

        // news & report
        // openModalReportAction(title) {
        //     // console.log(title)
        //     this.titleModalReport = title;
        //     this.$store.dispatch('news/getDetailReport', title);
        //     this.openModalReport = !this.openModalReport;
        // },
        loadMoreReport() {
            this.page_report += 1;
            this.getRelatedReport();
        },
        getRelatedReport(reset) {
            if (reset) {
                this.list_report = [];
                this.page_report = 1;
                // this.$store.commit('news/setRelatedReport', {});
                this.$store.commit('news/setListNews', {});
            }
            const query = this.detail.title ? this.detail.title : '';
            // const entityClass = 'PER';
            // const dateFrom = moment(this.dateRange.startDate).format('YYYY-MM-DD');
            // const dateTo = moment(this.dateRange.endDate).format('YYYY-MM-DD');

            // this.$store.dispatch('news/getRelatedReport', [this.limit_report, this.page_report, query, 'PER', '', dateFrom, dateTo, this.source_related_report]);

            const searchData = {
                // 'limit_word': [
                //     {
                //         'id': (this.selectedBdi.id?this.selectedBdi.id:null),
                //         'name': this.selectedBdi.title?this.selectedBdi.title:(this.selectedBdi.name?this.selectedBdi.name:null),
                //     },
                // ],
                'related_news': query,
            };

            // this.$store.dispatch('news/getListNews', [this.limit_report, this.page_report, searchData, dateFrom, dateTo] );
            this.$store.dispatch('news/getListNews', [this.limit_report, this.page_report, searchData] );
        },
        async pickerUpdate(e) {
            // let ranges = moment(e.endDate.getTime()).diff(moment(e.startDate.getTime()), 'days') + 1;
            // this.date_from = moment(this.dateRange.startDate).format('YYYY-MM-DD');
            // this.date_to = moment(this.dateRange.endDate).format('YYYY-MM-DD');
            this.getRelatedReport(true);
        },
    },
    mounted() {
        // this.getData();
        if (this.status_detail !== 'loading') {
            this.getRelatedReport(true);
        }
    },
    filters: {
        date: function(date) {
            return moment(date).locale('id').format('DD MMM YYYY');
        },
    },
};
</script>

<style lang="sass">
    .topic-issue
        @apply grid grid-cols-5 w-full relative
        &__tweet
            @apply col-span-3 border-r
            border-color: var(--border-theme-darkness)
        &__trending
            @apply col-span-2

        .topic-issue-content
            &__header
                @apply p-4
            &__body
                height: 70vh
                @apply overflow-auto

        &__detail
            @apply absolute inset-0 w-full h-full bg-white z-10 hidden
</style>
